<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
        <h4 class="color-light ml-2 page_title">
          <span class="ul_text">AC</span>TIVITY LOGS
        </h4>
      </CRow>
      <CCardBody class="col-md-12">
        <CRow class="col-md-12">
          <CCol class="col-md-4 col-12">
            <CRow>
              <label>Date Range: </label>
            </CRow>
            <CRow>
              <span
                class="form-control"
                @click="() => (date_modal = true)"
                style="cursor: pointer"
              >
                {{ changeDateFormate(startDate) }} <b>-</b>
                {{ changeDateFormate(endDate) }}
              </span>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
              >
                <date-range-picker
                  :from="date_data.endDate"
                  :to="date_data.startdate"
                  :future="false"
                  :panel="date_data.panel"
                  :showControls="false"
                  @select="update"
                />
                <template #footer>
                  <CButton @click="date_modal = false" color="danger"
                    >Close</CButton
                  >
                </template>
              </CModal>
            </CRow>
          </CCol>
          <CCol class="col-md-2 col-12 mt-2 mt-md-0">
            <CRow class="col-md-12">
              <label>Select Action:</label>
            </CRow>
            <CRow>
              <CSelect
                class="col-md-12"
                required
                :value.sync="input.action"
                :options="action_opt"
              >
              </CSelect>
            </CRow>
          </CCol>
          <CCol class="col-md-3 col-12">
            <CRow>
              <label> Select User: </label>
             </CRow>
            <CRow>
            <multiselect
                class=""
                v-model="input.subuser"
                placeholder="Select User"
                label="email"
                track-by="email"
                :options="sub_user_opt"
                :multiple="true"
                :taggable="true"
                :preselect-first="true"
                :close-on-select="false"
                size="sm"
              >
              </multiselect>
              </CRow>
          </CCol>
          <CCol class="col-md-3 col-12">
            <CRow class="col-md-12">&zwnj; </CRow>
            <CRow class="ml-4 mt-2"
              ><CButton class="ml-3" color="success" @click="FilterLogs()"
                >Get Logs</CButton
              >
            </CRow>
          </CCol>
        </CRow>
        <div v-if="loading == true">Loading . . .</div>
        <div v-else>
          <CRow>
            <CCol>
              <CRow class="col-md-12 mt-3">
                <label>Total Rows : {{ totalRows }}</label>
              </CRow>
            </CCol>
            <CCol>
              <div class="card-header-actions">
                <small class="text-muted">
                  <multiselect
                    class="pb-2"
                    size="sm"
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="perPage"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="checkPage()"
                    placeholder="PerPage"
                    v-model="tableperpage"
                  ></multiselect
                ></small></div
            ></CCol>
          </CRow>
          <!-- Main table element -->
          <CDataTable
            :items="items"
            :dark="false"
            :items-per-page="tableperpage"
            :fields="fields"
            hover
            striped
            border
            small
            fixed
            column-filter
            light
          >
            <template #createdAt="{ item }">
              <td class="cell">
                {{
                  new Date(item.createdAt).toLocaleDateString("en-US", {
                    day: "numeric",
                    year: "numeric",
                    month: "long",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                }}
              </td></template
            >
            <template #action="{ item }">
              <td class="cell">
                {{ action_label[item.action] }}
              </td></template
            >
            <template #payload="{ item }">
              <td class="cell">
                <span class="link" @click="ShowPayload(item.payload)"
                  >show</span
                >
              </td>
            </template>
          </CDataTable>
          <CModal
            title="Payload"
            :show.sync="payload_modal"
            :centered="true"
            scrollable
            color="dark"
          >
            <CCardBody>
              <pre>{{ payload_details }}</pre>
            </CCardBody>
            <template #footer>
              <CButton
                @click="payload_modal = false"
                color="danger"
                style="display: none"
                >Close</CButton
              >
            </template>
          </CModal>
          <div class="pagination">
            <a href="#" @click="getLogs('prev')">&laquo;</a>
            <a href="#" class="active">{{ pageno }}</a>
            <a href="#" @click="getLogs('next')">&raquo;</a>
          </div>
        </div>
      </CCardBody></CCard
    >
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import SettingService from "../../services/settingControlService";
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import SignUpService from "./../../services/signupControlService";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  components: { Multiselect, DateTimePicker },
  data() {
    return {
      payload_details: {},
      payload_modal: false,
      date_data: { startdate: null, enddate: null, panel: null },
      date_modal: false,
      fields: [
        { key: "_id", label: "Event Id" },
        { key: "action", label: "Action" },
        { key: "ipaddress", label: "IP Address" },
        { key: "useragent", label: "User Agent" },
        { key: "apipath", label: "API Path" },
        { key: "createdAt", label: "Created At" },
        { key: "useremail", label: "User Email" },
        { key: "payload", label: "Payload" },
      ],
      tableperpage: 20,
      perPage: [20, 50, 100],
      pageno: 1,
      items: [],
      totalRows: 1,
      currentPage: 1,
      filter: {},
      filterOn: [],
      startDate: new Date(),
      endDate: new Date(),
      input: {
        edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        action: "",
        subuser: [],
      },
      useremail: "",
      loading: false,
      filtered_item: [],
      floading: false,
      all_data: [],
      action_label: { 1: "Read", 2: "Create", 3: "Update", 4: "Delete" },
      action_opt: [
        { label: "All", value: "" },
        { label: "Read", value: 1 },
        { label: "Create", value: 2 },
        { label: "Update", value: 3 },
        { label: "Delete", value: 4 },
      ],
      sub_user_opt: [],
    };
  },
  computed: {},
  async mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true"
    ) {
      this.$router.push("/");
    } else {
      if (localStorage.getItem("activity_log_table") != null)
        this.tableperpage = parseInt(
          localStorage.getItem("activity_log_table")
        );
      this.useremail = window.localStorage.getItem("email");
      this.dateFilter({
        startDate: new Date(this.startDate.setHours(0, 0)),
        endDate: new Date(this.endDate.setHours(23, 59)),
      });
      this.getUsers();
      this.getLogs();
    }
  },
  methods: {
    ShowPayload(payload) {
      this.payload_details = payload;
      this.payload_modal = true;
    },
    customFilter(field) {
      let search = this.filter[field].toString().toLowerCase();
      let f_data = [];
      f_data = this.all_data.filter((row) =>
        row[field].toString().toLowerCase().includes(search)
      );
      this.items = f_data;
    },
    swap(json) {
      var ret = {};
      for (var key in json) {
        ret[json[key]] = key;
      }
      return ret;
    },
    changeDateFormate(d) {
      var p = new Date(d);
      return p.toLocaleDateString("en-US", {
        day: "numeric",
        year: "numeric",
        month: "long",
      });
    },
    update(values) {
      this.date_data.startdate = values.from;
      this.date_data.enddate = values.to;
      this.date_data.panel = values.panel;
      this.dateFilter({
        startDate: new Date(values.from),
        endDate: new Date(values.to),
      });
      this.date_modal = false;
    },
    dateFilter(data) {
      this.input.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.input.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate;
      this.endDate = data.endDate;
    },
    checkPage() {
      localStorage.setItem("activity_log_table", this.tableperpage);
      this.getLogs();
    },
    FilterLogs() {
      this.pageno = 1;
      this.getLogs();
    },
    async getLogs(txt) {
      try {
        if (txt == "next") {
          this.pageno++;
        }
        if (txt == "prev") {
          if (this.pageno != 1) {
            this.pageno--;
          }
        }
        var payload = {
          pageSize: this.tableperpage,
          currentPage: this.pageno,
          filters: { sdate: this.input.sdate, edate: this.input.edate },
        };
        if (this.input.action != "") {
          payload.filters.action = this.input.action;
        }
        if(this.input.subuser.length >0){
          payload.filters.users = [];
          this.input.subuser.map(t => {
            payload.filters.users.push(t.email);
          })
        }
        this.loading = true;
        let response = await SettingService.getActivitylogs(payload);
        this.items = response.data;
        this.all_data = response.data;
        this.totalRows = this.items.length;
        this.loading = false;
      } catch (e) {
        console.error("Error in login");
        console.log(e);
      }
    },
    async getUsers() {
      let response;
      try {
        this.sub_user_opt = [];
        response = await SignUpService.getSubusers();
        let subusers = response.message;
        subusers.map((t) => {
          this.sub_user_opt.push({
            email: this.useremail,
          });
        this.sub_user_opt.push({
          email: t.email,
        });
      });
      } catch (e) {
        console.error("Error in login");
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  font-size: 22px;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.convurl {
  width: 28%;
}
.cell {
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}
.cell:hover {
  text-overflow: clip;
  overflow: unset;
  white-space: normal;
}
</style>
